import React from 'react';
import copy from 'clipboard-copy'; // Import clipboard-copy library
import code from '../assets/images/code.jpg';

const Blog2 = () => {
  const handleBackButtonClick = () => {
    window.location.href = '/';
  };
  const copyToClipboard = (codeSnippet) => {
    copy(codeSnippet);
  };
  const codeSnippet1 = `
  // In Startup.cs
  app.UseSwagger();
  app.UseSwaggerUI(c =>
  {
      c.SwaggerEndpoint("/swagger/v1/swagger.json", "My API V1");
  });
  `;

  const codeSnippet2 = `
/// <summary>
/// Gets a specific item by ID.
/// </summary>
/// <param name="id">The ID of the item.</param>
/// <returns>The requested item.</returns>
[HttpGet("{id}")]
public ActionResult<Item> Get(int id)
{
    // Implementation
}
  `;

  return (
    <div className="blog">
      <div className="container-fluid blog-header-image-container">
        <img alt="code" src={code} className="blog-header-image" />
      </div>
      <div className="container blog-content-container mt-4">
        <div className="row">
          <div className="col-sm">
            <div className="card border-0">
              <h2>
                .NET Core Web API with OpenAPI: Streamlining Development and
                Documentation
              </h2>
              <h6 className="card-subtitle mb-2 text-sm grey">
                December 18, 2023
              </h6>
              <div className="mt-5">
                <p>
                  In the dynamic landscape of web development, creating and
                  maintaining APIs can be a complex task. Enter OpenAPI – a
                  powerful specification that simplifies API development,
                  documentation, and collaboration. This blog explores the
                  significance of <code>OpenAPI</code>, its core concepts,
                  benefits, and practical implementation in a{' '}
                  <code>.NET Core Web API</code>
                  project.
                </p>
                <p>
                  OpenAPI is a specification designed for building APIs,
                  providing a standardized way for humans and computers to
                  understand a service's capabilities without delving into
                  source code or extensive documentation.
                </p>
                <p>
                  Formerly known as <b>Swagger</b>, OpenAPI has evolved into a
                  widely adopted standard. It has become a cornerstone in the
                  industry, offering a common language for API development.
                </p>
                <br />
                <h5 className="card-title mb-3">Benefits of Using OpenAPI</h5>
                <p>
                  <strong>Consistent Documentation: </strong>One of the key
                  advantages of OpenAPI is the automatic generation of
                  comprehensive and consistent API documentation. This feature
                  ensures that API documentation is always up-to-date and in
                  sync with the actual implementation.
                </p>
                <p>
                  <strong>Improved Collaboration: </strong>OpenAPI enhances
                  collaboration between development and documentation teams. By
                  providing a shared specification, it streamlines the
                  development process, fostering effective communication and
                  reducing misunderstandings.
                </p>
                <p>
                  <strong>Code Generation: </strong>OpenAPI allows for the
                  generation of client libraries, server stubs, and API
                  documentation from the specification. This feature accelerates
                  development by providing a foundation that developers can
                  build upon.
                </p>
                <p>
                  <strong>Validation and Testing: </strong>With OpenAPI,
                  automated validation of API requests and responses becomes a
                  reality. This reduces the chances of runtime errors, enhancing
                  the overall reliability of the API.
                </p>

                <h5 className="card-title mt-5 mb-3">
                  Getting Started with OpenAPI in a .NET Core Web API Project
                </h5>
                <h6 className="mt-4">1. Installing Swashbuckle.AspNetCore</h6>
                <p>
                  To integrate OpenAPI into a .NET Core Web API project, start
                  by installing the <code>Swashbuckle.AspNetCore</code> NuGet
                  package. This package acts as the bridge between your API and
                  the OpenAPI specification.
                </p>
                <div className="code-container">
                  <pre>
                    <code>dotnet add package Swashbuckle.AspNetCore</code>
                  </pre>
                  <button
                    className="btn"
                    onClick={() =>
                      copyToClipboard(
                        'dotnet add package Swashbuckle.AspNetCore'
                      )
                    }
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
                <h6 className="mt-5">2. Configuring Swagger Middleware</h6>
                <p>
                  Next, configure Swagger middleware in the Startup.cs file to
                  expose OpenAPI documentation. This step involves adding a few
                  lines of code to specify the Swagger JSON endpoint and the
                  Swagger UI endpoint.
                </p>
                <div className="code-container">
                  <pre>
                    <code>{codeSnippet1}</code>
                  </pre>
                  <button
                    className="btn"
                    onClick={() => copyToClipboard(codeSnippet1)}
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
                <h6 className="mt-5">3. Adding XML Comments</h6>
                <p>
                  Enhance the quality and detail of generated documentation by
                  incorporating XML comments in your code. Document your
                  controllers, actions, and models to provide clear insights
                  into the purpose and usage of each component.
                </p>
                <div className="code-container">
                  <pre>
                    <code>{codeSnippet2}</code>
                  </pre>
                  <button
                    className="btn"
                    onClick={() => copyToClipboard(codeSnippet2)}
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
                <h6 className="mt-5">4. Customizing OpenAPI Specification</h6>
                <p>
                  OpenAPI is flexible and allows customization of the generated
                  specification. Tailor the specification to meet specific
                  project requirements by adjusting settings and annotations.
                </p>

                <h5 className="card-title mt-5">Conclusion</h5>
                <p>
                  In conclusion, OpenAPI is a valuable tool for modern API
                  development. By embracing OpenAPI, developers can streamline
                  collaboration, ensure consistent documentation, and enhance
                  the overall quality of their APIs. Whether for in-house or
                  public APIs, OpenAPI proves to be a versatile and essential
                  component in the developer's toolkit. As you embark on your
                  API journey, consider integrating OpenAPI into your .NET Core
                  Web API projects to experience these benefits firsthand.
                  Explore the possibilities, and empower your team with a
                  standardized approach to API development and documentation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="backButton"
        className="btn floating-button"
        onClick={handleBackButtonClick}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
    </div>
  );
};

export default Blog2;
