import React from 'react';
import copy from 'clipboard-copy'; // Import clipboard-copy library
import code from '../assets/images/code.jpg';

const Blog1 = () => {
  const handleBackButtonClick = () => {
    window.location.href = '/';
  };
  const copyToClipboard = (codeSnippet) => {
    copy(codeSnippet);
  };
  const codeSnippet1 = `
    const winston = require('winston');

    // Configure Winston logger
    const logger = winston.createLogger({
      level: 'info',
      format: winston.format.simple(),
      transports: [
        new winston.transports.Console(),
      ],
    });

    module.exports = logger;
  `;

  const codeSnippet2 = `
  const { AsyncLocalStorage } = require('async_hooks');

  // Create an instance of AsyncLocalStorage
  const asyncLocalStorage = new AsyncLocalStorage();
  
  // Wrap Winston logger methods to include AWSRequestId
  const loggerWithAWSRequestId = {
    info: (message, meta) => {
      const awsRequestId = asyncLocalStorage.getStore();
      logger.info({ message, meta, awsRequestId });
    },
    error: (message, meta) => {
      const awsRequestId = asyncLocalStorage.getStore();
      logger.error({ message, meta, awsRequestId });
    },
    // Add other log levels as needed
  };
  
  module.exports = loggerWithAWSRequestId;
  
  `;

  const codeSnippet3 = `
  const logger = require('./logger');

  exports.handler = async (event, context) => {
    // Associate AWSRequestId with the current execution context
    asyncLocalStorage.run(context.awsRequestId, () => {
      // Your Lambda function logic here
  
      // Example log statements
      logger.info('Lambda function executed successfully', { additionalData: 'some data' });
      logger.error('An error occurred in the Lambda function', { error: 'error details' });
    });
  };
  
  `;

  return (
    <div className="blog">
      <div className="container-fluid blog-header-image-container">
        <img alt="code" src={code} className="blog-header-image" />
      </div>
      <div className="container blog-content-container mt-4">
        <div className="row">
          <div className="col-sm">
            <div className="card border-0">
              <h2>
                Building a Logging System for AWS Lambda using Node JS
                AsyncLocalStorage
              </h2>
              <h6 className="card-subtitle mb-2 text-sm grey">
                January 24, 2024
              </h6>
              <div className="mt-5">
                <p>
                  Logging is a crucial aspect of application development,
                  especially in distributed systems like microservices. In the
                  context of AWS Lambda functions, effective logging is
                  essential for troubleshooting and debugging. In this blog
                  post, we'll explore how to design and implement a logging
                  library using
                  <code>Winston</code>
                  and the <code>AsyncLocalStorage</code> className from the
                  Node.js core module.
                </p>
                <p>
                  This logging library will tag each log statement with the
                  AWSRequestId, making it significantly easier to trace and
                  troubleshoot issues in AWS Lambda functions.
                </p>
                <br />
                <h5 className="card-title">1. Setting Up Winston</h5>
                <p>
                  Winston is a versatile logging library for Node.js. Start by
                  installing it in your Node.js project:
                </p>
                <div className="code-container">
                  <pre>
                    <code>npm install winston</code>
                  </pre>
                  <button
                    className="btn"
                    onClick={() => copyToClipboard('npm install winston')}
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
                <p>
                  Next, create a <code>logger.js</code> file to configure and
                  export your Winston logger:
                </p>

                <div className="code-container">
                  <pre>
                    <code>{codeSnippet1}</code>
                  </pre>
                  <button
                    className="btn"
                    onClick={() => copyToClipboard(codeSnippet1)}
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>

                <h5 className="card-title mt-5">
                  2. Integrating AsyncLocalStorage
                </h5>
                <p>
                  Node.js provides the <code>AsyncLocalStorage </code>class in
                  its core module, allowing developers to create a context that
                  persists across asynchronous operations. This is particularly
                  useful for associating data with specific tasks, such as the{' '}
                  <code>AWSRequestId</code> in our case.
                </p>
                <p>
                  Add the following code to your <code>logger.js </code>file:
                </p>
                <div className="code-container">
                  <pre>
                    <code>{codeSnippet2}</code>
                  </pre>
                  <button
                    className="btn"
                    onClick={() => copyToClipboard(codeSnippet2)}
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
                <h5 className="card-title mt-5">
                  3. Using the Logging Library in Lambda Functions
                </h5>
                <p>
                  Now, let's see how to use this logging library in your{' '}
                  <code>AWS Lambda</code> functions. In your{' '}
                  <code>Lambda handler </code>or function file, include the
                  following:
                </p>
                <div className="code-container">
                  <pre>
                    <code>{codeSnippet3}</code>
                  </pre>
                  <button
                    className="btn"
                    onClick={() => copyToClipboard(codeSnippet3)}
                  >
                    <i class="bi bi-clipboard"></i>
                  </button>
                </div>
                <h5 className="card-title mt-5">4. Benefits and Conclusion</h5>
                <p>
                  By using this logging library, each log statement in your AWS
                  Lambda function will now include the AWSRequestId , allowing
                  you to trace and troubleshoot issues more efficiently. When
                  viewing CloudWatch logs, you can easily filter and correlate
                  log entries for a specific Lambda execution.
                </p>
                <p>
                  In conclusion, implementing a robust logging system is
                  essential for maintaining and debugging serverless
                  applications. The combination of Winston and AsyncLocalStorage
                  provides a powerful solution for tagging log statements with
                  contextual information, streamlining the troubleshooting
                  process for developers.
                </p>
                <p>
                  Feel free to customize and extend the provided code based on
                  your specific requirements and application structure.
                </p>
                <p className="my-3">Happy logging!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="backButton"
        className="btn floating-button"
        onClick={handleBackButtonClick}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
    </div>
  );
};

export default Blog1;
