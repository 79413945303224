import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/agnes-logo.png';
import accentrue from '../assets/images/accenture.png';
import profile from '../assets/images/profile-wt.png';
import bmo from '../assets/images/bmo.png';
import governmentOfAlberta from '../assets/images/government-of-alberta.png';
import lush from '../assets/images/lush.png';
import mcKesson from '../assets/images/mcKesson.png';
import aws from '../assets/images/aws.png';
import visualStudio from '../assets/images/visual-studio.png';
import customApps from '../assets/images/custom-apps.png';
import consulting from '../assets/images/consulting.png';
import learning from '../assets/images/learning.png';

const Home = () => {
  const handleReturnButtonClick = () => {
    const targetElement = document.getElementById('services');

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleBackToTopButtonClick = () => {
    const targetElement = document.getElementById('home');

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <main>
      <nav className="navbar navbar-expand-lg">
        <div className="container d-flex justify-content-start mr-2">
          <button
            className="navbar-toggler m-2 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo01"
          >
            <div className="d-flex">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                <li className="nav-item m-1">
                  <a
                    className="nav-link rounded"
                    aria-current="page"
                    href="#services"
                  >
                    Services
                  </a>
                </li>
                <li className="nav-item m-1">
                  <a className="nav-link rounded" href="#projects">
                    Projects
                  </a>
                </li>
                <li className="nav-item m-1">
                  <a className="nav-link rounded" href="#clients">
                    Clients
                  </a>
                </li>
                <li className="nav-item m-1">
                  <a className="nav-link rounded" href="#blog">
                    Blog
                  </a>
                </li>
                <li className="nav-item m-1">
                  <a className="nav-link rounded" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item m-1">
                  <a className="nav-link rounded" href="#contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* home */}
      <section
        id="home"
        className="container-fluid home-container d-flex flex-column"
      >
        <div className="home-content d-flex align-items-start">
          <div className="container mt-3">
            <div className="row">
              <div
                className="col d-flex flex-row align-items-center"
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-easing="linear"
              >
                <img alt="agnes-logo" src={logo} className="agnes-logo" />
                <h1 className="text-700 text-xl ls-md dark-blue agnes-text">
                  gnes
                </h1>
              </div>
            </div>
            <div className="row">
              <div
                className="col col-sm "
                data-aos="fade-right"
                data-aos-delay="1000"
                data-aos-duration="800"
                data-aos-easing="linear"
              >
                <h3 className="text-lg ls-xs dark-blue">IT Consulting </h3>
              </div>
            </div>
            <div className="row">
              <div
                className="col col-sm d-flex flex-row align-items-center"
                data-aos="fade-right"
                data-aos-delay="1000"
                data-aos-duration="800"
                data-aos-easing="linear"
              >
                <span className="text-700 text-lg mb-2 blue">&</span>
                <h3 className="text-200 mx-3 text-lg ls-sm dark-blue">
                  Learning
                </h3>
              </div>
            </div>
            <div className="row  text-500 dark-grey show-lg-only">
              <div
                className="col-xs"
                data-aos="fade-right"
                data-aos-delay="1000"
                data-aos-duration="800"
                data-aos-easing="linear"
              >
                <span className="ls-xs">
                  We provide a variety of IT consulting, custom software
                  development, and learning services to individuals, small-scale
                  and large organizations.
                </span>
              </div>
            </div>
            <div className="row">
              <div
                className="col col-sm"
                data-aos="fade-right"
                data-aos-delay="1000"
                data-aos-duration="800"
                data-aos-easing="linear"
              >
                <button
                  className="btn btn-primary text-500 read-more-button ls-sm mt-5"
                  data-aos="fade-right"
                  data-aos-delay="2000"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                  onClick={handleReturnButtonClick}
                >
                  READ MORE
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* services */}
      <section
        id="services"
        className="container services-container padding-x-lg mt-4"
      >
        <div className="row">
          <div className="col d-flex flex-items-row align-items-center">
            <i className="bi bi-briefcase bi-lg cherry"></i>
            <h5 className="section-title">Services</h5>
          </div>
        </div>
        <div className="row mt-4">
          <div
            className="col-sm"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-easing="linear"
          >
            <div className="card card-border shadow">
              <div className="card-body text-center">
                <img
                  className="services-icon mt-3"
                  alt="custom-apps"
                  src={customApps}
                />
                <h5 className="card-title mt-4 ">Custom Apps</h5>
                <div className="card-text lh-lg mt-4 ">
                  Embracing agile methodologies, we specialize in crafting
                  bespoke applications. With us, you maintain complete ownership
                  of the code.
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-sm my-4"
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-duration="500"
            data-aos-easing="linear"
          >
            <div className="card card-border shadow">
              <div className="card-body text-center">
                <img
                  className="services-icon mt-3"
                  alt="consulting"
                  src={consulting}
                />
                <h5 className="card-title mt-4 ">Staff Augmentation</h5>
                <div className="card-text lh-lg mt-4 ">
                  Joining forces with your team, we ensure you have control over
                  the project, its timeline, and budget. Drawing from our
                  experience, we guide you through the IT transformation
                  journey.
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-sm"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-easing="linear"
            data-aos-delay="1000"
          >
            <div className="card card-border shadow">
              <div className="card-body text-center">
                <img
                  className="services-icon mt-3"
                  alt="learning"
                  src={learning}
                />
                <h5 className="card-title mt-4 ">IT Learning</h5>
                <div className="card-text lh-lg mt-4 ">
                  Guiding learners of all ages entering IT, our curriculum spans
                  computer science basics, high school math, and key programming
                  languages – Python, and JavaScript.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* projects */}
      <section
        id="projects"
        className="container-fluid  projects-container padding-x-xl mt-4"
      >
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-items-row align-items-center">
              <i className="bi bi-braces bi-lg green"></i>
              <h5 className="section-title">Projects</h5>
            </div>
          </div>
          <div className="row  mt-4">
            <div className="col col-lg">
              <div className="card  border-0">
                <h5 className="card-title">Application Digitization</h5>
                <div className="card-body">
                  <div className="card-text">
                    In a transformative project, we successfully automated the
                    paper-based application process to obtain a crucial
                    government document. By embracing cloud solutions, we not
                    only enhanced efficiency but also significantly bolstered
                    data security, replacing the vulnerability of paper records
                    with a robust and secure digital infrastructure. The
                    transition to a seamless, paperless workflow marked a
                    milestone, ensuring faster, more secure, and accessible
                    services for citizens.
                  </div>
                  <h6 className="card-subtitle  text-sm grey mt-1">
                    Node JS, NEST, Type Script, AWS, IaC CDK, and Docker
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row my-3">
            <div className="col col-lg">
              <div className="card  border-0">
                <h5 className="card-title ">Migration to Cloud</h5>
                <div className="card-body">
                  <div className="card-text lh-md ">
                    In a strategic move towards efficiency and
                    cost-effectiveness, we successfully migrated a key
                    application to a cloud-based serverless solution. By
                    utilizing serverless architecture and services offered by
                    AWS, we ensured optimal scalability, enhanced performance,
                    and seamless resource management. This project exemplifies
                    our commitment to leveraging cutting-edge technologies for
                    streamlined operations and substantial cost savings.
                  </div>
                  <h6 className="card-subtitle  text-sm grey mt-1">
                    AWS Lambda, Api Gateway, Dynamo DB, and React
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col col-lg">
              <div className="card  border-0">
                <h5 className="card-title">Platform Modernization</h5>
                <div className="card-body">
                  <div className="card-text lh-md">
                    We successfully migrated a legacy system built on
                    PowerBuilder and Oracle Forms, to a modernized and
                    future-ready architecture.This transformation involved a
                    complete rewrite of the application, coupled with extensive
                    process re-engineering and user interface enhancements. The
                    result is a cutting-edge solution that not only meets
                    current technological standards but also delivers an
                    improved and user-friendly experience.
                  </div>
                  <h6 className="card-subtitle  text-sm grey mt-1">
                    C#, .Net Core, Angular, SQL, and AZURE AD
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* clients */}
      <section
        id="clients"
        className="container-fluid   bg-white  text-center padding-x-xl"
      >
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            <div
              className="col col-lg padding-x-lg"
              data-aos="flip-up"
              data-aos-duration="500"
              data-aos-easing="linear"
            >
              <img alt="BMO" src={bmo} />
            </div>
            <div
              className="col col-lg padding-x-lg"
              data-aos="flip-up"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-easing="linear"
            >
              <img alt="Government Of Alberta" src={governmentOfAlberta} />
            </div>
            <div
              className="col col-lg padding-x-lg"
              data-aos="flip-up"
              data-aos-delay="800"
              data-aos-duration="500"
              data-aos-easing="linear"
            >
              <img alt="Accentrue" src={accentrue} />
            </div>
            <div
              className="col col-lg padding-x-lg"
              data-aos="flip-up"
              data-aos-delay="1100"
              data-aos-duration="500"
              data-aos-easing="linear"
            >
              <img alt="McKesson" src={mcKesson} />
            </div>
            <div
              className="col col-lg padding-x-lg"
              data-aos="flip-up"
              data-aos-delay="1600"
              data-aos-duration="500"
              data-aos-easing="linear"
            >
              <img alt="Lush" src={lush} />
            </div>
          </div>
        </div>
      </section>

      {/* blogs */}
      <section id="blog" className="container padding-x-lg  mt-4">
        <div className="row">
          <div className="col d-flex flex-items-row align-items-center">
            <i className="bi bi-chat-right-text bi-lg blue"></i>
            <h5 className="section-title">Blogs</h5>
          </div>
        </div>
        {/* Blog 1 and 2 */}
        <div className="row">
          <div className="col-sm mt-4">
            <div id="blog1" className="d-flex align-items-center card-border">
              <div className="flex-shrink-0 p-3">
                <img className="blog-icon-md" alt="aws" src={aws} />
              </div>

              <div className="flex-grow-1 p-3">
                <h6 className="card-subtitle mb-2 text-sm grey">
                  January 24, 2024
                </h6>

                <h5 className="card-title">
                  <Link
                    to="/blog1"
                    className="link"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Building a Logging System for AWS Lambda using Node JS
                    AsyncLocalStorage
                  </Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-sm mt-4">
            <div id="blog2" className="d-flex align-items-center card-border">
              <div className="flex-shrink-0 p-3">
                <img
                  className="blog-icon-sm"
                  alt="visual-studio"
                  src={visualStudio}
                />
              </div>

              <div className="flex-grow-1 p-3">
                <h6 className="card-subtitle mb-2 text-sm grey">
                  December 18, 2023
                </h6>

                <h5 className="card-title">
                  <Link
                    to="/blog2"
                    className="link"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    .NET Core Web API with OpenAPI: Streamlining Development and
                    Documentation
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* about */}
      <section
        id="about"
        className="container-fluid  about-container padding-x-xl mt-4"
      >
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-items-row align-items-center">
              <i className="bi bi-person bi-lg orange"></i>
              <h5 className="section-title">About</h5>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm order-sm-2 d-flex justify-content-center align-items-center ">
              <img alt="Sumeet Sethi" src={profile} className="rounded-image" />
            </div>
            <div className="col-sm order-sm-1 mt-4">
              <div className="card">
                <div className="card-body">
                  <span className="card-text">
                    Hello, I'm Sumeet Sethi, the Director of Software
                    Development and Co-founder of AGNES Inc. <br />
                    <br />
                    With a wealth of experience in IT consulting, finance, and
                    healthcare industries, I ventured into entrepreneurship in
                    2019 and AGNES was born. I oversee end-to-end software
                    development activities, including design, architecture,
                    solutioning, and post-implementation support. <br />
                    <br />
                    The name <span className="display-7">AGNES</span> was
                    inspired by my family. It is a combination of the first
                    letters of the names of my children (
                    <span className="display-7">A</span>naya and{' '}
                    <span className="display-7">G</span>arv), my wife (
                    <span className="display-7">NE</span>ha), and of course (
                    <span className="display-7">S</span>umeet).
                  </span>
                  <div id="contact" className="d-flex flex-column mt-4">
                    <span>
                      <i className="bi bi-phone"></i>
                      <span className="m-2">+1 416 801 2175</span>
                    </span>
                    <span className="mt-2">
                      <i className="bi bi-envelope"></i>
                      <span className="m-2">agnesinc.ca@gmail.com</span>
                    </span>
                    <span className="mt-2">
                      <Link
                        to="https://www.linkedin.com/in/dev-sumeet-sethi"
                        className="link "
                      >
                        <i className="bi bi-linkedin"></i>
                      </Link>
                      <Link
                        to="https://github.com/agnesinc/agnes"
                        className="link "
                      >
                        {' '}
                        <i className="bi bi-github mx-3"></i>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* credits */}
      <section
        id="credits"
        className="container-fluid py-2 credits-container text-xs"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm text-center">
              <span className="mx-3">
                {' '}
                Creafted with <span className="red">&#x2764;</span> &#xA9; 2024
                Copyright
              </span>
              <a href="https://www.vecteezy.com/free-photos">
                Photos by Vecteezy
              </a>
            </div>
          </div>
        </div>
      </section>

      <button
        id="backToTopButton"
        className="btn btn-primary floating-button"
        data-aos="fade-down"
        data-aos-duration="500"
        data-aos-easing="linear"
        onClick={handleBackToTopButtonClick}
      >
        <i className="bi bi-arrow-up"></i>
      </button>
    </main>
  );
};

export default Home;
