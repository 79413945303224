import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Blog1 from './components/Blog1';
import Blog2 from './components/Blog2';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/blog1" element={<Blog1 />}></Route>
      <Route path="/blog2" element={<Blog2 />}></Route>
    </Routes>
  );
}

export default App;
